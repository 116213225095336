import { Box, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { format, isValid, subHours } from "date-fns";

import { Card } from "shared";
import { EmrClose } from "@medstonetech/slate-icons";
import { ORDER_STATUS_COLOR } from "modules/orders";
import { OrderItem } from "modules/charts-shared/types";
import { OrderStatus } from "enums";
import { toDisplay } from "utils/enum-mapper";
import { useMemo } from "react";

type OrderFormCartItemProps = {
  item: OrderItem;
  onDelete?: () => void;
  isFromHistory?: boolean;
  orderStatus?: OrderStatus;
};

function OrderFormCartItem({
  item: {
    orderElementType,
    description,
    notes,
    date,
    location,
    orderElementCategory,
    administrationTime,
    scheduledTime,
    isObservation,
    isPRN,
    prnReason,
  },
  onDelete,
  orderStatus,
  isFromHistory = false,
}: OrderFormCartItemProps) {
  const borderColor = orderStatus
    ? ORDER_STATUS_COLOR[orderStatus]
    : "gray.700";

  const displayDate = useMemo(() => {
    if (isFromHistory) {
      return scheduledTime && isValid(new Date(scheduledTime))
        ? new Date(scheduledTime)
        : date;
    }
    return date;
  }, [date, isFromHistory, scheduledTime]);

  return (
    <Card
      w="100%"
      p="10px 20px"
      variant="basic"
      borderLeft="8px solid"
      borderColor={borderColor}
    >
      <Flex justifyContent="space-between">
        <Box flex={1}>
          <Flex>
            <Text flex={1} color="gray">
              {orderElementType}
            </Text>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              flex={2}
              columnGap="10px"
            >
              {isFromHistory && (
                <>
                  <Text color="gray">{format(displayDate, "MM-dd-yyyy")}</Text>
                  <Text color="gray">{format(displayDate, "HH:mm a")}</Text>
                </>
              )}
            </Box>
          </Flex>
          <Box display="flex" flexDir="column" flex={1}>
            <Text fontWeight="500">{description}</Text>
            {orderElementCategory && (
              <Text color="gray">
                {toDisplay("orderFormElementCategory", orderElementCategory)}
              </Text>
            )}
          </Box>
          <Flex>
            <Text fontWeight="500">{!!location?.length ? location : ""}</Text>
          </Flex>
          <Flex>
            <Text fontWeight="500">{notes}</Text>
          </Flex>
          <Flex>
            {administrationTime && isValid(new Date(administrationTime))
              ? format(new Date(administrationTime), "MM/dd/yyyy HH:mm")
              : ""}
          </Flex>
          {!isFromHistory && (
            <Flex>
              <Text color="gray">
                {scheduledTime && isValid(new Date(scheduledTime))
                  ? `Scheduled: ${format(
                      subHours(new Date(scheduledTime), 6),
                      "MM/dd/yyyy HH:mm a"
                    )}`
                  : ""}
              </Text>
            </Flex>
          )}
          <Flex>
            <Text color="gray">{isPRN && `PRN: ${prnReason}`}</Text>
          </Flex>
          <Flex>
            <Text color="gray">{isObservation ? "Observation Order" : ""}</Text>
          </Flex>
        </Box>
        {onDelete && (
          <Flex alignItems="flex-start" justifyContent="center">
            <IconButton
              aria-label="Delete Item"
              variant="icon"
              color="red"
              onClick={onDelete}
            >
              <Icon as={EmrClose} />
            </IconButton>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}

export { OrderFormCartItem };
